<template>
  <!-- 车商管理 -->
  <div class="carDealerManage">
    <!-- 上半部分 -->
    <div ref="top-box" class="top-box">
      <!-- 操作栏 -->
      <div class="ActionBar">
        <div class="actionBar-box">
          <div class="col-1">车商名称：</div>
          <div class="col-2">
            <el-select v-model="seeForm.id" clearable placeholder="请选择">
              <el-option
                v-for="item in providerList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">客服电话：</div>
          <div class="col-2">
            <el-input
              v-model="seeForm.consumerHotline"
              clearable
              type="number"
              placeholder="请输入客服电话"
            ></el-input>
          </div>
        </div>
        <div class="actionBar-box">
          <div class="col-1" style="width: 108px">订单提醒号码：</div>
          <div class="col-2">
            <el-input
              v-model="seeForm.orderReminderPhone"
              clearable
              type="number"
              placeholder="请输入订单提醒号码"
            ></el-input>
          </div>
        </div>
        <div class="actionBar-box">
          <el-button
            type="primary"
            size="small"
            @click="
              () => {
                this.seeForm.currentPage = 1;
                this.renderData();
              }
            "
            >查询</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="
              () => {
                this.show = true;
                this.formShow = true;
                this.headerText = '添加';
                this.STATUS = 'add';
                this.charterPopupWidth = 480;
              }
            "
            >添加</el-button
          >
        </div>
      </div>
      <!-- 车商表格 -->
      <Table
        :table-data="tableData"
        :title-name="titleName"
        :operation="true"
        :col-class-type="true"
        operation-width="150"
        style="height: calc(100% - 128px - 44px)"
        :table-height="topTableHeight"
        :highlight-current-row="true"
        class="defaultTable"
        @row-click="rowClickFun"
      >
        <template slot-scope="scope">
          <div>
            <el-button
              type="text"
              size="small"
              sort="primary"
              @click="onUpdate(scope.scopeRow, 'see')"
              >查看</el-button
            >
            <el-button
              type="text"
              size="small"
              sort="primary"
              @click="onUpdate(scope.scopeRow)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              sort="danger"
              @click="onDelete(scope.scopeRow)"
              >删除</el-button
            >
          </div>
        </template>
      </Table>
      <div ref="pagination" class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          :page-size="seeForm.pageSize"
          :current-page="seeForm.currentPage"
          @current-change="onCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 下半部分 -->
    <div ref="bottom-box" class="bottom-box">
      <el-tabs v-model="defaultName" type="border-card" style="height: 100%">
        <el-tab-pane label="包车" name="fourth">
          <el-button type="primary" size="small" @click="selectCarTypeFun"
            >选择车型分类</el-button
          >
          <Table
            :table-data="tableData2"
            :title-name="titleName2"
            :col-class-type="true"
            operation-width="160"
            :table-height="bottomTableHeight"
          ></Table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <charterPopup
      v-if="show"
      :onOff="true"
      :disabled="disabled"
      :width="charterPopupWidth"
      :header-text="headerText"
      @confirmFun="confirmFun"
      @closePopupFun="clearForm"
    >
      <template v-if="formShow">
        <el-form
          ref="Form"
          :model="Form"
          :rules="rules"
          label-width="140px"
          label-position="right"
          class="demo-form dialog-form"
        >
          <el-form-item label="车商名称：" prop="companyId">
            <companySelect
              :disabled="disabled || (headerText == '编辑' ? true : false)"
              :clearable="true"
              :company-tree="companyList"
              :valuename="valueAddName"
              :value="valueAdd"
              @getValue="getAddGroupId"
            ></companySelect>
          </el-form-item>
          <el-form-item label="订单提醒号码：" prop="phone">
            <div class="addNumberBox">
              <div
                v-for="(item, index) in phoneNumberArray"
                :key="index"
                style="position: relative"
              >
                <el-input
                  v-model.trim="phoneNumberArray[index]"
                  clearable
                  maxlength="11"
                  :disabled="disabled"
                  placeholder="请输入订单提醒号码"
                  onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                ></el-input>
                <el-button
                  v-show="index >= 1"
                  class="reduce"
                  @click="reducePhoneNumberFun(index)"
                  >-</el-button
                >
              </div>
              <el-button
                v-show="!disabled && this.phoneNumberArray.length < 5"
                class="add"
                @click="addPhoneNumberFun"
                >+</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="客服电话：" prop="customerPhone">
            <div class="addNumberBox">
              <div
                v-for="(item, index) in customerServicePhone"
                :key="index"
                style="position: relative"
              >
                <el-input
                  v-model.trim="customerServicePhone[index]"
                  clearable
                  maxlength="11"
                  :disabled="disabled"
                  placeholder="请输入客服电话号码"
                  onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                ></el-input>
                <el-button
                  v-show="index >= 1"
                  class="reduce"
                  @click="reducecustomerFun(index)"
                  >-</el-button
                >
              </div>
              <el-button
                v-show="!disabled && this.customerServicePhone.length < 5"
                class="add"
                @click="addcustomerFun"
                >+</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="开通业务：" required>
            <el-input value="包车" disabled />
          </el-form-item>
          <el-form-item label="地址：" prop="address">
            <div class="searchBox">
              <el-input
                id="tipinput"
                v-model.trim="Form.address"
                :disabled="disabled"
                placeholder="输入搜索地址"
                clearable
                type="text"
                @focus="searchInputFocusFun"
                @blur="searchInputBlurFun"
                @clear="searchInputClearFun"
              ></el-input>
              <div
                :class="searchShow ? 'searchActive' : ''"
                class="searchContent"
              >
                <div
                  v-for="(item, index) in searchList"
                  :key="index"
                  class="content-address"
                  @mousedown="searchClick(item)"
                >
                  <span class="address">{{ item.name }}</span>
                  <span class="addressDetail">{{ item.address }}</span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="图片：" prop="Form1IMG">
            <el-upload
              ref="elUpload"
              :disabled="disabled"
              action
              list-type="picture-card"
              :auto-upload="false"
              :multiple="false"
              :on-change="imageSuccessFun"
              :on-remove="imageListRemove"
              :file-list="fileList"
              :limit="2"
              :class="{ hide: disabled }"
              class="upload-demo"
            >
              <i class="el-icon-plus"></i>
              <div v-if="!disabled" slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过240kb
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </template>
      <!-- 包车表格 -->
      <carTypeTable
        v-if="carTypeShow"
        :prop-data="tableData2"
        @selectData="carTypeTableCheck"
        @imgSee="imgSee"
      ></carTypeTable>
    </charterPopup>
    <div
      v-show="dialogVisible"
      class="imgPopup"
      @click="() => (dialogVisible = false)"
    >
      <img :src="dialogImageUrl" alt @click.stop="() => {}" />
    </div>
  </div>
</template>

<script>
import host from "@/api/static/host.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
import carTypeTable from "./carTypeTable.vue";
import {
  getCompanyTree,
  uploadFileAPI,
  carDealerAddAPI,
  carDealerRenderAPI,
  carDealerDeleteAPI,
  carDealerUpdateAPI,
  modelTypesAddAPI,
  byProviderListRenderAPI,
  enableAndDisableAPI,
} from "@/api/lib/api.js";

export default {
  components: {
    companySelect,
    carTypeTable,
  },
  data() {
    return {
      providerList: [],
      charterPopupWidth: 480,
      carTypeShow: false,
      formShow: false,
      dialogVisible: false,
      dialogImageUrl: "",
      STATUS: "",
      searchStatus: false,
      searchShow: false,
      disabled: false,
      searchList: [],
      headerText: "",
      show: false,
      Form: {
        companyId: "",
        address: "", //车商地址
        latitude: "", //纬度
        longitude: "", //经度
        openBusiness: [4], //开通业务
        orderReminderPhone: [], //订单提醒号码
        consumerHotline: [], //客服电话
        logo: "",
      },
      seeForm: {
        currentPage: 1,
        pageSize: 4,
        orderReminderPhone: "",
        consumerHotline: "",
        id: "",
        openBusiness: 4,
      },
      valueAdd: null,
      valueAddName: null,
      companyList: [],
      carDealerList: [], // 车商名称下拉列表
      phoneNumberArray: [""], // 订单提醒号码
      customerServicePhone: [""], //客服电话
      total: 0,
      tableData: [],
      tableData2: [],
      titleName2: [
        {
          title: "车型分类名称",
          props: "vehicleTypeName",
          width: "150",
        },
        {
          title: "分类描述",
          props: "modelTypeDescription",
        },
        {
          title: "座位数",
          props: "seatNum",
        },
        {
          title: "载客数",
          props: "passengerNum",
        },
        {
          title: "行李数",
          props: "luggageNum",
        },
        {
          title: "图片",
          props: "picture",
          render: (h, params, index) => {
            return h("i", {
              class: "iconfont its_gy_image",
              style: {
                "font-size": "18px",
                cursor: "pointer",
                color: "#336FFE",
              },
              on: {
                click: (e) => {
                  e.stopPropagation();
                  this.dialogVisible = true;
                  this.dialogImageUrl = host.imgURL + params.picture;
                },
              },
            });
          },
        },
        {
          title: "启用",
          props: "vehicleModelTypeStatus",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status === 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  enableAndDisableAPI({
                    id: params.id,
                    status: params.status == 0 ? 1 : 0,
                  }).then((res) => {
                    if (res.code == "SUCCESS") {
                      this.byProviderListRenderFun();
                    }
                  });
                },
              },
            });
          },
        },
      ],
      titleName: [
        {
          title: "Logo",
          props: "logo",
          render: (h, params, index) => {
            return h("i", {
              class: "iconfont its_gy_image",
              style: {
                "font-size": "18px",
                cursor: "pointer",
                color: "#336FFE",
              },
              on: {
                click: (e) => {
                  e.stopPropagation();
                  this.dialogVisible = true;
                  this.dialogImageUrl = host.imgURL + params.logo;
                },
              },
            });
          },
        },
        {
          title: "车商名称",
          props: "name",
        },
        {
          title: "客服电话",
          props: "consumerHotline",
          SpecialJudgment: (res) => {
            const list = res || [];
            return list.join(",");
          },
        },
        {
          title: "订单提醒号码",
          props: "orderReminderPhone",
          SpecialJudgment: (res) => {
            const list = res || [];
            return list.join(",");
          },
        },
        {
          title: "开通业务",
          SpecialJudgment: (res) => {
            return "包车";
          },
        },
        {
          title: "地址",
          props: "address",
        },
      ],
      imgBase64: "",
      imgMsg: "",
      imgSize: 0,
      fileList: [],
      rules: {
        displacement: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                callback("请输入排量");
              } else if (value > 6) {
                callback("排量最大值6");
              } else {
                callback();
              }
            },
          },
        ],
        fuelTankCapacity: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                callback("请输入油箱容量");
              } else if (value > 150) {
                callback("油箱容量最大值150L");
              } else {
                callback();
              }
            },
          },
        ],
        carModelName: [
          { required: true, trigger: "blur", message: "请输入车型名称" },
        ],
        pickLocationIdList: [
          { required: true, trigger: "change", message: "请选择取车点" },
        ],
        intakeFormCode: [
          { required: true, trigger: "change", message: "请选择进气形式" },
        ],
        gearboxCode: [
          { required: true, trigger: "change", message: "请选择变速箱" },
        ],
        carBodyStructureCode: [
          { required: true, trigger: "change", message: "请选择车身结构" },
        ],
        vehicleModelTypeId: [
          { required: true, trigger: "change", message: "请选择车型分类" },
        ],
        reversingRadar: [
          { required: true, trigger: "change", message: "请选择倒车雷达" },
        ],
        Form1IMG: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.imgBase64 == "") {
                if (
                  this.fileList.length > 0 &&
                  this.fileList[0].size == undefined
                ) {
                  callback();
                } else {
                  callback("请上传图片！");
                }
              } else {
                if (this.imgMsg === "jpg" || this.imgMsg === "png") {
                  if (this.imgSize < 245760) {
                    callback();
                  } else {
                    callback("图片大小超过限制！");
                  }
                } else {
                  callback("上传文件格式不正确！");
                }
              }
            },
            trigger: "blur",
          },
        ],
        customerPhone: [
          {
            required: true,
            validator: this.customerPhoneFun,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: this.phoneRuleFun,
            trigger: "blur",
          },
        ],
        companyId: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.Form.companyId == "") {
                callback("请选择车商！");
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        address: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (this.Form.latitude == "") {
                callback("请在搜索结果中选择详细地址");
              } else {
                callback();
              }
            },
          },
        ],
      },
      topTableHeight: 0,
      bottomTableHeight: 0,
      checkTableData: [],
      checkTabldID: null,
      carServiceId: null, //车商ID
      updateOnoff: false,
      defaultName: "fourth",
    };
  },
  created() {
    this.getCompanyList();
  },
  mounted() {
    // 获取车商名称下拉框数据
    this.providerListFun().then((res) => {
      this.providerList = res;
    });
    // 获取表格数据
    this.renderData().then((res) => {
      if (res) {
        // 默认选中第一行的样式
        if (this.tableData.length > 1) {
          this.checkTabldID = this.tableData[0].id;
          this.carServiceId = this.tableData[0].companyId;
          const times = setTimeout(function () {
            document
              .querySelectorAll(".defaultTable table tbody tr")[0]
              .click();
            clearTimeout(times);
          }, 400);
        }
      }
    });
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  watch: {
    "Form.address": {
      handler(val) {
        if (
          !this.searchStatus &&
          (this.STATUS == "add" || this.STATUS == "update")
        ) {
          if (val) {
            if (this.updateOnoff) {
              this.updateOnoff = false;
            } else {
              this.searchFun(val);
            }
          } else {
            if (val == "") {
              this.Form.latitude = "";
              this.Form.longitude = "";
            }
            this.searchShow = false;
          }
        } else {
          if (val == "") {
            this.Form.latitude = "";
            this.Form.longitude = "";
          }
          this.searchStatus = false;
        }
      },
    },
    locationType: {
      handler(val) {
        if (val == 1) {
          this.locationDisabled = false;
        } else {
          this.locationDisabled = true;
        }
      },
    },
  },
  methods: {
    // 点击选择车型分类按钮
    selectCarTypeFun() {
      this.show = true;
      this.carTypeShow = true;
      this.headerText = "选择车型分类";
      this.charterPopupWidth = 900;
    },
    // 车型列表渲染数据表格
    byProviderListRenderFun() {
      byProviderListRenderAPI({ carServiceProviderId: this.checkTabldID }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            this.tableData2 = res.data;
          }
        }
      );
    },
    // 车商分类新增方法
    carTypeAdd() {
      let ary = [];
      this.checkTableData.map((item) => {
        ary.push(item.id);
      });
      modelTypesAddAPI({
        carServiceProviderId: this.checkTabldID,
        VehicleModelTypeIds: ary,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.carTypeShow = false;
          this.show = false;
          this.byProviderListRenderFun();
        }
      });
    },
    // 车型分类表格选择返回的check数据
    carTypeTableCheck(check) {
      this.checkTableData = this.deepClone(check);
    },
    // 车型分类选择的图片弹窗
    imgSee(picture) {
      this.dialogVisible = true;
      this.dialogImageUrl = host.imgURL + picture;
    },
    // 编辑查看按钮点击方法
    onUpdate(
      {
        companyId,
        orderReminderPhone,
        consumerHotline,
        openBusiness,
        latitude,
        longitude,
        address,
        logo,
        name,
        id,
      },
      val
    ) {
      this.valueAddName = null;
      this.valueAdd = null;
      this.charterPopupWidth = 480;
      // 判断当前状态是查看还是编辑
      if (val == "see") {
        this.STATUS = "see";
        this.headerText = "查看";
        this.disabled = true;
      } else {
        this.headerText = "编辑";
        this.updateOnoff = true;
        this.STATUS = "update";
      }
      this.formShow = true;
      this.show = true;
      // 进行客服和订单提醒号码的赋值
      if (orderReminderPhone.length == 0) {
        this.phoneNumberArray = [""];
      } else {
        this.phoneNumberArray = this.deepClone(orderReminderPhone);
      }
      if (consumerHotline.length == 0) {
        this.customerServicePhone = [""];
      } else {
        this.customerServicePhone = this.deepClone(consumerHotline);
      }
      this.$nextTick(() => {
        this.$set(this, "valueAddName", name);
        this.$set(this, "valueAdd", companyId);
      });
      this.Form = {
        companyId,
        openBusiness,
        latitude,
        longitude,
        address,
        id,
        logo,
      };
      this.fileList = [{ url: host.imgURL + logo }];
    },
    // 删除方法
    onDelete(scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        type: "warning",
        message: h("p", null, [h("span", null, "确认删除？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          carDealerDeleteAPI({ id: scope.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("删除成功");
              this.renderData();
            }
          });
        })
        .catch(() => {});
    },
    // 选择完图片后转为base64并保存
    imageSuccessFun(file, fileList) {
      this.fileList = [file];
      let that = this;
      this.imgMsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      this.imgSize = file.size;
      var a = new FileReader();
      a.readAsDataURL(file.raw); //读取文件保存在result中
      a.onload = function (e) {
        that.imgBase64 = e.target.result; //读取的结果在result中
        that.$refs.Form.validateField("Form1IMG");
      };
    },
    // 移除图片事件
    imageListRemove(file, fileList) {
      this.imgBase64 = "";
      this.imgMsg = "";
      this.imgSize = "";
      this.fileList = [];
      this.$refs.Form.clearValidate("Form1IMG");
    },
    // 搜索地址栏被清空的时候触发
    searchInputClearFun() {
      this.Form.latitude = "";
      this.Form.longitude = "";
    },
    // 搜索地址栏获得焦点事件
    searchInputFocusFun() {
      if (this.Form.address) {
        this.searchFun(this.Form.address);
      }
    },
    // 搜索地址栏失去焦点事件
    searchInputBlurFun() {
      let t = setTimeout(() => {
        this.searchShow = false;
        this.searchList = [];
        clearTimeout(t);
      }, 100);
    },
    // 点击下拉的地址
    searchClick(item) {
      let lat = item.location.lat;
      let lng = item.location.lng;
      this.Form = Object.assign({}, this.Form, {
        address: item.address,
        latitude: lat,
        longitude: lng,
      });
      this.$nextTick(() => {
        this.$refs.Form.validateField("address");
      });
      this.searchStatus = true;
    },
    // 搜索获取地址方法
    searchFun(val) {
      let vm = this;
      AMap.plugin("AMap.PlaceSearch", function () {
        var autoOptions = {
          city: "四川",
          extensions: "all",
          citylimit: true, //是否强制在设置的城市内搜索
          autoFitView: true,
          children: 1,
        };
        var placeSearch = new AMap.PlaceSearch(autoOptions);
        placeSearch.search(val, function (status, result) {
          // 搜索成功时，result即是对应的匹配数据
          if (result.info == "OK") {
            vm.searchList = result.poiList.pois;
            vm.searchShow = true;
          }
        });
      });
    },
    // 清空
    clearForm() {
      this.Form = {
        companyId: "",
        address: "", //车商地址
        latitude: "", //纬度
        longitude: "", //经度
        openBusiness: [4], //开通业务
        orderReminderPhone: [], //订单提醒号码
        consumerHotline: [], //客服电话
        logo: "",
      };
      this.carTypeShow = false;
      this.show = false;
      this.formShow = false;
      this.STATUS = "";
      this.searchStatus = false;
      this.searchShow = false;
      this.disabled = false;
      this.headerText = "";
      this.phoneNumberArray = [""];
      this.customerServicePhone = [""];
      this.fileList = [];
    },
    // 确定按钮回调
    confirmFun() {
      if (this.formShow) {
        this.$refs.Form.validate((valid) => {
          if (valid) {
            if (this.STATUS == "add") {
              this.addDataFun();
            } else {
              this.updateDataFun();
            }
          }
        });
      } else if (this.carTypeShow) {
        this.carTypeAdd();
      }
    },
    // 修改编辑方法
    updateDataFun() {
      new Promise((res, rej) => {
        this.Form.orderReminderPhone = this.deepClone(this.phoneNumberArray);
        this.Form.consumerHotline = this.deepClone(this.customerServicePhone);
        if (this.fileList[0].size != undefined) {
          this.imageUploadFun().then((jump) => {
            this.Form.logo = jump;
            res(this.Form);
          });
        } else {
          res(this.Form);
        }
      }).then((rs) => {
        carDealerUpdateAPI(rs).then((res) => {
          if (res.code == "SUCCESS") {
            this.$message.success("修改成功");
            this.clearForm();
            this.renderData();
          }
        });
      });
    },
    // 上传图片方法
    imageUploadFun() {
      return new Promise((resolve, reject) => {
        // FormData 对象
        let form = new FormData();
        // 文件对象
        form.append("files", this.fileList[0].raw);
        uploadFileAPI(form).then((res) => {
          if (res.code == "SUCCESS") {
            resolve(res.data[0]);
          }
        });
      });
    },
    // 数据添加方法
    addDataFun() {
      this.imageUploadFun().then((res) => {
        this.Form.orderReminderPhone = this.deepClone(this.phoneNumberArray);
        this.Form.consumerHotline = this.deepClone(this.customerServicePhone);
        this.Form.logo = res;
        carDealerAddAPI(this.Form).then((resolve) => {
          if (resolve.code == "SUCCESS") {
            this.$message.success("添加成功");
            this.providerListFun().then((res) => {
              this.providerList = res;
            });
            this.seeForm = {
              currentPage: 1,
              pageSize: 4,
              orderReminderPhone: "",
              consumerHotline: "",
              id: "",
              openBusiness: 4,
            };
            this.clearForm();
            this.renderData();
          }
        });
      });
    },
    // 车商表格数据查询
    renderData() {
      return new Promise((resolve) => {
        carDealerRenderAPI(this.seeForm).then((res) => {
          if (res.code == "SUCCESS") {
            if (res.data.list.length > 0) {
              this.checkTabldID = res.data.list[0].id;
              this.carServiceId = res.data.list[0].companyId;
              // 默认选中第一行的样式
              const times = setTimeout(function () {
                document
                  .querySelectorAll(".defaultTable table tbody tr")[0]
                  .click();
                clearTimeout(times);
              }, 400);
              this.rowClickFun(res.data.list[0]);
              this.tableData = res.data.list;
              this.total = res.data.total;
              resolve(true);
            } else {
              this.tableData = [];
              this.total = 0;
              resolve(false);
            }
          }
        });
      });
    },
    // 企业回显
    getAddGroupId(v) {
      if (v == null) {
        this.Form.companyId = "";
      } else {
        this.Form.companyId = v;
      }
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 计算表格高度
    computeHeight() {
      const topHeight = this.$refs["top-box"].clientHeight;
      const bottomHeight = this.$refs["bottom-box"].clientHeight;
      this.topTableHeight = topHeight - 128 - 44;
      this.bottomTableHeight = bottomHeight - 100;
    },
    // 点击某一行的时候
    rowClickFun(row) {
      this.checkTabldID = row.id;
      this.carServiceId = row.companyId;
      this.byProviderListRenderFun();
    },
    // ----------------------------------------------------------------

    // 当前页码改变
    onCurrentChange(page) {
      this.seeForm.currentPage = page;
      this.renderData();
      // 默认选中第一行的样式
      const times = setTimeout(function () {
        document.querySelectorAll(".defaultTable table tbody tr")[0].click();
        clearTimeout(times);
      }, 400);
    },
    // 当前条数改变
    onSizeChange(size) {
      this.seeForm.pageSize = size;
      this.seeForm.currentPage = 1;
      this.renderData();
    },
    // 点击添加订单提醒号码
    addPhoneNumberFun() {
      const len = this.phoneNumberArray.length - 1;
      if (this.phoneNumberArray[len].trim() == "") {
        this.$message.error("请输入手机号后再进行添加");
      } else {
        this.phoneNumberArray.push("");
      }
    },
    // 点击去掉订单提醒号码
    reducePhoneNumberFun(index) {
      this.phoneNumberArray.splice(index, 1);
    },
    // 点击添加客服电话
    addcustomerFun() {
      const len = this.customerServicePhone.length - 1;
      if (this.customerServicePhone[len].trim() == "") {
        this.$message.error("请输入客服号码后再进行添加");
      } else {
        this.customerServicePhone.push("");
      }
    },
    // 点击去掉客服电话号码
    reducecustomerFun(index) {
      this.customerServicePhone.splice(index, 1);
    },
    // 手机号校验规则
    phoneRuleFun(rule, value, callback) {
      let reg = /(^1[3|4|5|7|8|9]\d{9}$)|(^09\d{8}$)/;
      if (this.phoneNumberArray.length == 1 && this.phoneNumberArray[0] == "") {
        callback("请输入订单提醒号码");
      } else {
        let on = true;
        for (let i = 0; i < this.phoneNumberArray.length; i++) {
          if (reg.test(this.phoneNumberArray[i]) == false) {
            callback("订单提醒号码格式错误");
            on = false;
            break;
          }
        }
        on && callback();
      }
    },
    // 客服电话校验规则
    customerPhoneFun(rule, value, callback) {
      let reg = /^(([0][1-9]{2,3}[0-9]{5,10})|(1(3|4|5|6|7|8|9)\d{9}))$/;
      let con = true;
      for (let j = 0; j < this.customerServicePhone.length; j++) {
        if (this.customerServicePhone[j] === "") {
          callback("请输入客服电话");
          con = false;
          break;
        }
        if (reg.test(this.customerServicePhone[j]) == false) {
          callback("客服电话号码格式错误");
          con = false;
          break;
        }
      }
      con && callback();
    },
  },
};
</script>

<style lang="scss" scoped>
.imgPopup {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  img {
    max-width: 70%;
    max-height: 70%;
  }
}
/deep/.hide .el-upload--picture-card {
  display: none;
}
/deep/.el-dialog__body {
  text-align: center !important;
}
/deep/.el-dialog__header {
  padding: 26px;
}
.searchBox {
  position: relative;
  .searchActive {
    visibility: visible !important;
  }
  .searchContent {
    visibility: hidden;
    .content-address {
      line-height: 0px;
    }
    :hover {
      background: #f5f5f5;
    }
    width: 200px;
    max-height: 320px;
    overflow-x: hidden;
    overflow-y: auto;
    background: #ffffff;
    border: 1px solid #336fee;
    border-radius: 4px;
    z-index: 10;
    position: absolute;
  }
  .address {
    width: calc(100% - 8px);
    display: inline-block;
    cursor: pointer;
    padding: 4px 4px 0 4px;
    line-height: 24px;
  }
  .addressDetail {
    width: calc(100% - 8px);
    display: inline-block;
    border-bottom: 1px solid #f4f4f4;
    cursor: pointer;
    padding: 0 4px 4px 4px;
    line-height: 24px;
    font-size: 12px;
    color: #afafaf;
  }
}
.addNumberBox {
  position: relative;
  .add {
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 0 !important;
    top: 8px;
    left: 210px;
  }
  .reduce {
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 0 !important;
    bottom: 8px;
    left: 210px;
  }
}
/deep/.el-tabs {
  height: 99.5% !important;
}
/deep/.el-tabs__content {
  height: calc(100% - 40px) !important;
}
.carDealerManage {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: calc(100vh - 132px);
  ::v-deep .el-select-dropdown {
    position: absolute !important;
    top: 30px !important;
  }
  .top-box,
  .bottom-box {
    width: 100%;
    height: 50%;
    overflow: hidden;
  }
  .ActionBar {
    background: #f7f8f9;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 10px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box:after {
      content: "";
      flex: auto;
    }
    .actionBar-box {
      width: calc(25% - 13px);
      line-height: 32px;
      margin-right: 14px;
      margin-bottom: 16px;
      display: flex;
      .col-1 {
        width: 84px;
        display: inline-block;
      }
      .col-2 {
        max-width: 210px;
        width: calc(100% - 84px);
        .el-select {
          width: 100%;
          max-width: 200px;
        }
      }
    }
  }
}
/deep/.el-input__inner {
  line-height: 20px;
}
/deep/.el-upload-list__item {
  transition: none !important;
}
/deep/.el-form-item__label {
  line-height: 40px !important;
  padding-right: 10px;
}
/deep/.el-upload-list__item-status-label {
  display: none !important;
}
</style>
